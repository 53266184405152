import React, { useState, useEffect } from 'react'
import { viewports } from 'styles/mq'

const ShowIf = ({ children, breakpoint, math = '>' }) => {
  const [width, setWidth] = useState(window.innerWidth)

  breakpoint = isNaN(breakpoint)
    ? parseInt(viewports[breakpoint])
    : breakpoint

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => setWidth(window.innerWidth))
    resizeObserver.observe(document.body)

    return () => resizeObserver.unobserve(document.body)
  },[])

  return eval(width + math + breakpoint) ? children : null
}

export default ShowIf


/* Example usage:

<ShowIf breakpoint="mobile" math="<">
  <Foo/>
</ShowIf>

*/