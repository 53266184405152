import styled, { th } from "@xstyled/styled-components"
import media from 'styles/mq'

export const Group = styled.div`
  display: flex;
  gap: var(--side-gap, 0);
  height: 100%;

  > *{
    position: sticky;
    top: var(--side-gap, 0);
    // border: 1px solid rgba(0,0,0,.22);
    box-shadow: 2px 3px 10px rgba(0,0,0,.1); // ${th.color('lightpurple')};
  }

  .variablesWrap{
    flex:1;
    min-width: 320px;

    > .variables{
      width: 100%;
    }
  }

  .ForecastGrid{
  }

  ${media.below("mobile")`
    flex-flow: column;
    gap: 3;
    margin: 1;

    > *{
      position: relative;
    }
  `}
`