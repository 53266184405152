import { bmRandom } from 'utils'

export default ({ vars, addedSpent={}, toggledVars }) => {
  const result = []
  const random = (min, max) => Math.round((Math.random() * (+max - +min)) + +min)
  const getValue = (name, param, opts = {}) => {
    const value = param[toggledVars[name]]
    return Array.isArray(value)
      ? opts.random == 'bm' ? bmRandom(...value, 0.7) : random(...value)
      : +value
  }
  const perc = n => (1 + n/100 )

  // https://stackoverflow.com/a/59268677/104380
  function round(num, precision) {
    var base = 10 ** precision;
    return +(Math.round(num * base) / base).toFixed(precision);
  }

  // assumes every extra cash added (in a specific year) is immediately re-invested
  for( let i = 0; i < vars.duration; i++ ){
    const lastYearTotal = (i ? result[i-1].total : vars.capital)
    const returnRate    = getValue('returnRate', vars.returnRate, { random: 'bm'})
    const extra         = addedSpent[i+1] ? addedSpent[i+1] * perc(returnRate/2) : 0

    // yearly projection
    let yp = {
      year      : i + 1,
      addedSpent: addedSpent[i+1],
      inflation : getValue('inflation', vars.inflation),
      taxPerc   : getValue('tax', vars.tax),
      returnRate
    }

    yp.withdrawal = toggledVars.monthlyWithdrawal == 'ratio'
      ? lastYearTotal * getValue('monthlyWithdrawal', vars.monthlyWithdrawal)/100
      : round(getValue('monthlyWithdrawal', vars.monthlyWithdrawal) * 12 * perc(yp.inflation/2*i), 0)

    yp.withdrawalCapitalRatio = lastYearTotal > 0 ? round((yp.withdrawal / lastYearTotal * 100), 2) : '∞'

    /* should only tax fully if the profits for this year are larger or equal to the withdrawal sum  */
    const currentYearGrossGains = (lastYearTotal - yp.withdrawal) * perc(returnRate)
    const taxableGains = Math.clamp(0, yp.withdrawal + currentYearGrossGains - lastYearTotal, yp.withdrawal)

    yp.taxPaid = Math.max(0, round((yp.taxPerc/100) * taxableGains, 0))

    yp.total = Math.round(
      (lastYearTotal - yp.withdrawal/2) * perc(returnRate)
      - yp.withdrawal/2 + extra - yp.taxPaid
    )

    result.push(yp)
  }

  return result
}