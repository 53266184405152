import React, { useCallback, useEffect, useMemo, useState } from "react"
import * as Styled from './Group.styles'
import { Variables, ForecastGrid } from "../"
import { useProjectionsContext } from 'store/context'
import { cx } from "utils"

const Group = ({ id, className }) => {
  const { groups, setVars, setToggledVars, setAddedSpent, projections } = useProjectionsContext()
  const { vars, toggledVars, addedSpent } = (groups[id] || {})

  if( !groups[id] ) return null

  return (
    <Styled.Group className={cx(className)}>
      <div className='variablesWrap'>
        <Variables
          vars={vars}
          setVars={setVars}
          setToggledVars={setToggledVars}
          toggledVars={toggledVars}
        />
      </div>
      { projections[id] &&
      <ForecastGrid
        className={cx(toggledVars.returnRate != 'fixed' && 'randomReturnRate')}
        projection={projections[id]}
        addedSpent={addedSpent}
        setAddedSpent={setAddedSpent}
      />
      }
    </Styled.Group>
  )
}

export default React.memo(Group)