const VERSION = 2 // current version of persisted data. if code change breaks persisted data, verison number should be bumped.
const STORE_KEY = 'foture-fortune'

const persist = {
  get(){
    let persistedData

    // use the data only if the version hasn't changed
    if( persist.isVersionMatch() ){
      try{ persistedData = JSON.parse(localStorage[`${STORE_KEY}/groups`]) }
      catch(err){}
    }

    return persistedData
  },

  set(data, activeGroup){
    localStorage.setItem(`${STORE_KEY}/v`, VERSION) // pesisted
    localStorage.setItem(`${STORE_KEY}/groups`, JSON.stringify( data ))

    dispatchEvent( new Event('storage') )
  },

  clearGroups(){
    localStorage.removeItem(`${STORE_KEY}/groups`)
  },

  isVersionMatch(){
    return localStorage.getItem(`${STORE_KEY}/v`) == VERSION
  },

  tour( done ){
    const KEY = `${STORE_KEY}/tour`

    if( done )
      localStorage.setItem(KEY, 1)
    else{
      return !!(localStorage.getItem(KEY) && persist.isVersionMatch())
    }
  }
}

export default persist