import styled, {th} from '@xstyled/styled-components'

export const ChatPointer = styled.span`
  font-size: calc(5em + 2vw);
`

export const Comments = styled.section`
  max-width: large;
  margin: medium auto 0;
  padding: 3;

  html:lang(he) &{
    direction: rtl;
  }

  > header{
    text-align: center;
    margin: 5em 0 7em;

    h2{
      font-size: huge;
      color: purple;
    }

    p{
      font-size: 4;
      white-space: pre-line;
    }
  }
`

export const Intro = styled.section`
  padding: 2;
  font-size: 3;
`