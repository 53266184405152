import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import styled, { th } from '@xstyled/styled-components'
import mq from 'styles/mq'

const Styled = {
  Calculations: styled.div`
    h2{
      margin: 0 0 .5em;
      color: blacks.1;
      font-weight: 400;
      font-size: 4;
      color: purple;
    }

    dt{
      font-size: 3;
      font-style: italic;
      color: blacks.1;
      font-weight: 500;
      display: inline-block;
      margin: 1em 0 .5em;
    }

    dd{
      margin: 0;
      display: block;
    }

    code{
      display: block;
      padding: 3;
      border-radius: 2;
      white-space: pre-wrap;
      background: white;
      box-shadow: 1;
      direction: ltr;
      font: 14px/1.6 monospace;
    }

    p{
      color: blacks.2;
    }
  `
}

const Calculations = () => {
  const {t} = useTranslation()

  return <Styled.Calculations>
    <h2>{t("calculations.title")}</h2>
    <dl>
      <dt>{t("calculations.random_return_rate")}</dt>
      <dd>
        <p>
          <Trans t={t} i18nKey="calculations.random_return_rate__info">
            <a href="https://klementoninvesting.substack.com/p/the-distribution-of-stock-market" target="_blank"></a>
            <a href="https://jsfiddle.net/vsync/frdzycv2/" target="_blank"></a>
          </Trans>
        </p>
      </dd>
      <dt>{t("calculations.year_end_total")}</dt>
      <dd>
        <code>
          (lastYearTotal - yearlyWithdrawal/2) * perc(returnRate) - yearlyWithdrawal/2 + extra - yearlyTaxPaid
        </code>
      </dd>

      <dt>{t("calculations.yearly_withdrawal")}</dt>
      <dd>
        <code>
        const perc = n =&gt; (1 + n/100 )
        <br/><br/>
        isFixedRatio<br/>
        &nbsp;&nbsp;? lastYearTotal * monthlyWithdrawal/100<br/>
        &nbsp;&nbsp;: monthlyWithdrawal * 12 * perc(inflation/2 * yearNumber)
        </code>
      </dd>

      <dt>{t("calculations.tax")}</dt>
      <dd>
        <code>
        currentYearGrossGains = (lastYearTotal - yp.withdrawal) * perc(returnRate)<br/>
        taxableGains = Math.clamp(0, yearlyWithdrawal + currentYearGrossGains - lastYearTotal, yearlyWithdrawal)<br/>
        yearlyTaxPaid = Math.max(0, round((yearlyTaxPercentage/100) * taxableGains, 0))
        </code>
      </dd>
    </dl>
  </Styled.Calculations>
}

export default Calculations