import {parse} from 'query-string'
import objectToQueryString from './objectToQueryString'
import getToggledVars from './getToggledVars'

export function setURL( data, activeGroup ){
  try{
    const { vars, toggledVars, addedSpent } = JSON.parse( JSON.stringify(data[activeGroup]) )
    const output = getToggledVars(vars, toggledVars)
    output.toggledVars = toggledVars
    output.addedSpent = addedSpent

    if( Object.keys(output) )
      window.history.replaceState('', '', '?' + objectToQueryString(output) )
  }
  catch(err){}
}

export function parseURL(){
  //const rawData = {"addedSpent[10]":"1111","addedSpent[7]":"555","color":"#F72585","toggledVars[inflation]":"random","toggledVars[monthlyWithdrawal]":"fixed","toggledVars[returnRate]":"fixed","toggledVars[tax]":"fixed","vars[capital]":"1000000","vars[duration]":"30","vars[inflation][fixed]":"2.5","vars[inflation][random][]":["-1","4"],"vars[monthlyWithdrawal][fixed]":"2000","vars[monthlyWithdrawal][random][]":["1000","3000"],"vars[monthlyWithdrawal][ratio]":"1","vars[returnRate][fixed]":"8","vars[returnRate][random][]":["-5","20"],"vars[tax][fixed]":"25","vars[tax][random][]":["15","35"]}
  const rawData = parse( window.location.search )

  const projection = Object.entries(rawData).reduce((acc, [key, val]) => {
    let keyArr = key.replace('[]', '')
                    .replace('][',',')
                    .replace('[',',')
                    .replace(']','')
                    .split(',')
                    .reverse()

    function createKeysFromArr(obj, arr){
      const k = arr.pop()

      obj[k] = arr.length
        ? obj[k] ? obj[k] : {}
        : val

      if( arr.length )
        createKeysFromArr(obj[k], arr)
    }

    val = Array.isArray(val)
      ? val.map(Number)
      : isNaN(val)
        ? val
        : +val

    createKeysFromArr(acc, keyArr)

    return acc
  }, {})

  return projection
}