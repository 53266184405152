import React, { useContext, createContext, useCallback } from 'react'
import * as Styled from './Tabs.styles'
import { cx } from 'utils'

const TabsContext = createContext({})

const Tabs = ({ children, className, activeKey, onSelect, ...rest }) => (
  <TabsContext.Provider value={{ activeKey, onSelect }}>
    <Styled.Tabs className={cx('tabs', className)} {...rest}>{children}</Styled.Tabs>
  </TabsContext.Provider>
)

Tabs.Tab = ({ children, className, tabKey, active, onClick, onRemove, ...rest }) => {
  const { activeKey, onSelect } = useContext(TabsContext)

  const onTabClick = () => {
    tabKey && onSelect(tabKey)
    onClick && onClick()
  }

  const onRemoveClick = e => {
    e.stopPropagation()
    onRemove(tabKey)
  }

  return (
    <Styled.Tab data-active={activeKey == (tabKey || children) ? '' : null} className={cx('tabs__tab', active && 'active', className)} onClick={onTabClick} {...rest}>
      {children}
      { onRemove ? <button onClick={onRemoveClick}>&times;</button> : ''}
    </Styled.Tab>
  )
}

Tabs.Content = ({ className, path, children }) => {
  const { activeKey } = useContext(TabsContext)

  // const selectedChild = children?.filter(c => c.props.tabKey == activeKey )

  return <Styled.TabsContent className={cx('tabs__content', className)}>
    {children}
  </Styled.TabsContent>
}

Tabs.context = TabsContext

export default Tabs
