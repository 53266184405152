import { createGlobalStyle, th } from '@xstyled/styled-components'
import reset from './reset'
import mq from 'styles/mq'

export default createGlobalStyle`
  ${reset}

  html{
    font-family: body;
    font-size: 12px;
  }

  :lang(he){
    font-family: Arial, Helvetica, sans-serif;
  }

  body {
    min-width: 800px;
    min-height: 100vh;

    overflow-y: scroll;
    -webkit-font-smoothing: antialiased;

    ${mq.below("mobile")`
      min-width: 0;
    `}
  }

  ::selection{
    background-color: blue;
    color: white;
  }

  a{
    color: pink;
  }

  #root{
    background: linear-gradient(to bottom, ${th.color('lightpurple')}, ${th.color('lightblue')} 30%);
    > main{
      > .page{
        min-height: 100vh;
      }
    }
  }

  [title]{ cursor: help; }

  .hidden{ display: none; }

  :lang(he) .titleTtip__text{
    direction: rtl;
    white-space: pre-line;
  }

  #titleTtip{
    --radius: 6px;
    --trans-duration: .2s;
    max-width: 220px;
    position: fixed;
    z-index: max;
    box-sizing: border-box;
    pointer-events: none;
    opacity: 0;
    transition: 0s;

    .titleTtip__text{
      padding: 6px 11px;
      border-radius: var(--radius);
      color: white;
      transform: scale(.75);
      transform-origin: 50% top;
      background: black;
      backdrop-filter: blur(3px);
    }

    &:after {
      --size: 8px;
      content: "";
      bottom: calc(100% - 1px);
      left: Clamp(var(--radius)/2, var(--mouse-pos, 0) * 1px - var(--size)/2, 100% - var(--size)*2 - var(--radius)/2 - 1px);
      right: 0;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: black;
      border-width: var(--size);
    }

    &[data-over]{
      --x: calc(var(--pos-left) + var(--window-scroll-x));
      --y: calc(var(--pos-top) + var(--window-scroll-y));
      position: absolute;
      z-index: 999999;
      top: 0;
      left: 0;
      transform: translate(calc(var(--x) * 1px),
                          calc(var(--y) * 1px));
    }

    /* Only tooltips which are positiond have "style" attribute */
    &[data-over] > *{
      transform: none;
      transition: var(--trans-duration);
    }

    &[data-over]{
      opacity: 1;
      transition: opacity var(--trans-duration);
    }
  }
`
