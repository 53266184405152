// https://medium.com/swlh/using-react-hooks-to-sync-your-component-state-with-the-url-query-string-81ccdfcb174f

import { useState, useCallback } from 'react'
import qs from 'query-string'

const setQueryStringWithoutPageReload = (qsValue) => {
  const {
    location: { protocol, host, pathname }
  } = window
  const newurl = protocol + '//' + host + pathname + qsValue
  window.history.pushState({ path: newurl }, '', newurl)
}

const setQueryStringValue = (key, value, queryString = window.location.search) => {
  const values = qs.parse(queryString)
  const newQsValue = qs.stringify({ ...values, [key]: value })
  setQueryStringWithoutPageReload(`?${newQsValue}`)
}

const getQueryStringValue = (key, queryString = window.location.search) => {
  const values = qs.parse(queryString)
  return values[key]
}

function useQueryString(key, initialValue) {
  const [qsValue, setValue] = useState(getQueryStringValue(key) || initialValue)
  const qsSetValue = useCallback(
    (newValue) => {
      setValue(newValue)
      setQueryStringValue(key, newValue)
    },
    [key]
  )

  return [qsValue, qsSetValue]
}

export default useQueryString
