import styled, {th} from '@xstyled/styled-components'

export const Tabs = styled.div``

export const Tab = styled.div`
  --border-size: 3px;
  --pad-side: ${th('space.small')};

  display: inline-flex;
  align-items: center;
  font-weight: 700;
  font-size: 2;
  color: gray;
  text-decoration: none;
  /* padding: 0 sm small;
  padding-bottom: calc(${th('space.small')}px + var(--border-size)); */
  cursor: pointer;
  transition: 0.2s ease-out;

  button{
    --size: 15px;
    border: none;
    width: var(--size);
    height: var(--size);
    line-height: calc(var(--size) + 2px);
    font-weight: 700;
    font-size: var(--size);
    border-radius: 50%;
    margin-left: auto;
    background-color: white;
    color: black;
    padding: 0;
    outline: none;
    cursor: pointer;
    // opacity: 0;
    transition: .1s;
  }
`

export const TabsContent = styled.div`
  flex: 1;
`
