import React from 'react'
import {DiscussionEmbed} from 'disqus-react'

const Comments = ({ post }) => {
  const disqusShortname = "future-fortune-com"
  const disqusConfig = {
    language: location.pathname.replace(/[\W_]+/g,"")
  }

  return <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
}
export default Comments