import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import en from './en.json5'
import he from './he.json5'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en
      },
      he: {
        translation: he
      }
    },
    lng: location.pathname.replace(/[\W_]+/g,""),
    fallbackLng: "en",

    react: {
      transSupportBasicHtmlNodes: true
    },

    interpolation: {
      escapeValue: false
    }
  })

document.documentElement.lang = i18n.language