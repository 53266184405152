import { colors } from 'styles/theme'

export default {
  chart: {
    id: 'projections-chart',
    zoom: {
      enabled: false
    },
    toolbar: {
      show: false
    },
    // redrawOnWindowResize: false,
    // redrawOnParentResize: false
    // animations: {
    //   enabled: false
    // }
  },

  colors: colors.pallete,

  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'straight',
  },
  grid: {
    yaxis: {
      lines: {
        show: true
      }
    },
    // column: {
    //   colors: ["#EEE", "transparent"],
    //   opacity: 0.5
    // } ,
    // row: {
    //   colors: ["#EEE", "transparent"],
    //   opacity: .5
    // },
  },

  yaxis: {
    axisBorder: {
      // show: false
    },
    tickAmount: 8,
    // opposite: true,
    labels: {
      style: {
        fontSize: '1.2em',
      },
      formatter: v => v && v.toLocaleString()
    }
  },

  xaxis: {
    labels: {
      rotate: 0,
      hideOverlappingLabels: false
    },
    axisTicks: {
      show: true,
    }
  },

  title: {
    text: "",
  },
}