import React, { useState, useEffect, useRef, useMemo } from 'react'
import styled, { th } from '@xstyled/styled-components'
import { hasTouch } from 'utils'
import '@yaireo/ui-range/ui-range.scss'

const Styled = {
  Slider: styled.div`
    // min-width: 200px;
    width: 100%;
    --thumb-size: 20px;
    --maxTicksAllowed: 0;
    --thumbs-too-close: Clamp(-1,   1000 * (Min(1, Max(var(--cb) - var(--ca) - 20, -1))  +  .001),    1);
    --progress-background: ${th.color('lightblue')};
    --thumb-close-to-min: Min(1, Max(var(--ca) - 15, 0)); // 2% threshold
    --thumb-close-to-max: Min(1, Max(85 - var(--cb), 0)); // 2% threshold
    --value-background-hover: ${th.color('blacks.1')};
    --primary-color: ${th.color('green500')};
    --thumb-shadow: 0 0 0 calc(var(--thumb-size)/4) inset var(--thumb-color),
                    0 0 0 99px ${th.color('green500')} inset,
                    0 0 0 2px ${th.color('green500')};

    input:active{
      --thumb-size: 16px;
      --thumb-shadow: revert;
    }

    direction: ltr;
  `
}

const noramlizeValue = v => Array.isArray(v) ? v : [v]

const getStyleVars = (name, value) => ({
  [`--value${name?`-${name}`:''}`]: value,
  [`--text-value${name?`-${name}`:''}`]: JSON.stringify(value.toLocaleString())
})


const Slider = ({ min = 0, max, step = 1, value:v, prefix, suffix, onChange }) => {
  const getInitalStyleValues = useMemo(() => v => {
    const specific = v.length > 1
      ? { ...getStyleVars('a', v[0]), ...getStyleVars('b', v[1]) }
      : { ...getStyleVars('', v[0]) }

    return {
      "--min":min,
      "--max":max,
      "--step":step,
      "--prefix":JSON.stringify(prefix),
      "--suffix":JSON.stringify(suffix),
      ...specific
    }
  }, [])


  const [value, setValue] = useState(noramlizeValue(v))
  const [style, setStyle] = useState({...getInitalStyleValues(value) })
  const isMounted = useRef()
  const wheelTimeout = useRef()

  const onInputLocal = e => {
    const { name, value } = e.target
    update(name, +value)
  }

  // maually update
  const update = (name, value) => {
    setValue(lastValue => {
      let newValue = lastValue.slice()
      newValue[name=='b'?1:0] = value

      onChange && onChange([...newValue].sort((a,b) => a - b))

      return newValue
    })

    setStyle(lastStyle => ({
      ...lastStyle,
      ...getStyleVars(name, value)
    }))
  }

  // when value is updated from outside
  useEffect(()=> {
    if( isMounted.current ){
      const nv = noramlizeValue(v)
      setValue( noramlizeValue(nv) )
      setStyle( {...getInitalStyleValues(nv) } )
    }

    isMounted.current = true
  }, [v])

  // useEffect(()=> {
  //   isMounted.current && onChange && onChange([...value].sort((a,b) => a - b))
  //   isMounted.current = true
  // }, [value])

  const onWheel = e => {
    if( hasTouch() ) return

    const { name, value, min, max } = e.target,
      delta = Math.sign(e.deltaY) * -1 // normalize jump value to either -1 or 1

    clearTimeout(wheelTimeout.current)
    wheelTimeout.current = setTimeout(() => {
      wheelTimeout.current = false
    }, 300)

    if( value ){
      let newVal = Math.min(Math.max(min,   +value + delta*step   ), max)
      update(name, newVal)
    }
  }

  // for passive event:false to block page scrolling while changing slider values
  useEffect(() => {
    const cancelWheel = e => wheelTimeout.current && e.preventDefault()
    document.body.addEventListener('wheel', cancelWheel, {passive:false})
    return () => document.body.removeEventListener('wheel', cancelWheel)
  }, [])

  const Range = useMemo(() => ({ value, name = "" }) => {
    return <>
      <input type="range" name={name} min={min} max={max} step={step} value={value} onInput={onInputLocal} onWheel={onWheel} />
      <output></output>
    </>
  }, [])

  return <>
    <Styled.Slider className="range-slider" style={style}>
      { ['a','b'].slice(0, value.length).map((name, i, arr) => <Range key={name} value={value[i]} name={arr.length > 1 ? name : ''} /> )}
      <div className='range-slider__progress'></div>
    </Styled.Slider>
  </>
}


export default Slider