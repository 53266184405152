import styled, {th} from '@xstyled/styled-components'
import { Button } from 'components/atoms'
import media from 'styles/mq'

export const Header = styled.header`
  html:lang(he) &{
    & > *{
      direction: rtl;
      unicode-bidi: embed;
    }
  }

  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  padding: small;
  height: headerHeight;
  background: white;
  width: 50%;
  text-align: right;

  ${media.lessThan('mobile')`
    background: none;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
  `}

  a{
    text-decoration: none;
    color: purple;
    line-height: 1;
    display: inline-block;
    transition: .1s;

    &:hover{
      color: black;
     // text-decoration: ${th.color('purple')}
    }
  }
`

export const TourButton = styled(Button)`
  margin-right: 2;
  padding: 1 2;
`

export const ResetButton = styled(Button)`
  --color: ${th.color("red")};
  margin-right: 2;
  padding: 1 2;
`