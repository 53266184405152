import React from 'react'
import styled, {th} from '@xstyled/styled-components'
import { cx } from 'utils'

const StyledButton = styled.button`
  --color: ${th.color("purple")};
  border: none;
  background-color: var(--color);
  color: white;
  border-radius: 2;
  padding: 2;
  cursor: pointer;
  text-transform: capitalize;
  outline: none;
  transition: .12s ease-out;

  &:hover{
    box-shadow: 0 0 0 3px var(--color);
  }
`

const Button = ({ className, children, ...rest }) =>
  <StyledButton className={cx('Button', className)} {...rest}>
    {children}
  </StyledButton>

export default Button
