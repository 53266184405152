import React, { useEffect, useState } from 'react'
import { Translation, Trans } from 'react-i18next'
import styled, {th} from '@xstyled/styled-components'
import Tour from 'reactour'
import { cx } from 'utils'
import { persist, hasMouse } from 'utils'
import media from 'styles/mq'

const StyledTour = styled(Tour)`
  font-size: medium;
  max-width: 600px;

  html:lang(he) &{
    direction: rtl;
    [data-tour-elem="left-arrow"],
    [data-tour-elem="right-arrow"]{
      transform: rotate(180deg);
    }
  }

  ${media.below("mobile")`
    max-width: 93% !important;
  `}

  button{
    font-family: inherit !important;
  }

  [data-tour-elem="badge"]{
    font-family: inherit;
  }

  [data-tour-elem="navigation"]{
    flex-wrap: nowrap;
  }

  [data-tour-elem="controls"]{
    justify-content: space-between;

    button:not(.reactour__dot){
      padding: 4px;
      box-sizing: content-box;
    }
  }
`

const StyledWelcome = styled.div`
  max-width: 580px;
  color: blacks.1;
  padding: 3;

  h2{
    font-size: 3;
    color: black;
    margin: 0px 0px 4 0px;
    text-align: center;

    em{
      font-style: normal;
      color: purple;
    }
  }

  center{
    font-size: 2;
    font-weight: 500;
  }

  .welcome__notice{
    padding-top: 2;
    border-top: 1px solid;
    border-color: grays.1;
    color: blacks.2;

    &::first-letter{
      font-size: 2;
    }
  }
`

const StyledTourDoneButton = styled.button`
  background-color: blue;
  color: white;
  border-radius: 2;
  border: none;
  outline: none;
  padding: small !important;
  cursor: pointer;
`

const steps = [
// welcome
  {
    style: {
      maxWidth: 'fit-content'
    },
    position: 'center',
    selector: '',
    content: () => <StyledWelcome>
      <Translation>{(t) =>
          <Trans t={t} i18nKey="tour.welcome">
            <h2><em></em></h2>
            <p></p>
            <center></center>
            <p className="welcome__notice"></p>
          </Trans>
      }</Translation>
    </StyledWelcome>
    ,
  },
// variables
  {
    selector: '.variables__years',
    content: () => <Translation>{(t) => <Trans t={t} i18nKey="tour.var_years">
        Select the number of <strong>years</strong> to run the projection for.
        <br/>
        <p className={cx(!hasMouse() && "hidden")}>
          You can use your mousewheel <big>🖱️</big> to fine-tune values by hovering the slider and moving the wheel
        </p>
      </Trans>
    }</Translation>,
  },
  {
    selector: '.variables__capital',
    content: () => <Translation>{(t) => t("tour.capital")}</Translation>,
  },
  {
    selector: '.variables__return',
    content: () => <Translation>{(t) => <Trans t={t} i18nKey="tour.return">
        Estimated investment return rate, in percentages, per year.
        <p>
          Choose <strong>Random</strong> option to set a range of possible values so <strong>each year</strong> will use a random value,
          which mimics the nature of some real-world investements
        </p>
      </Trans>
    }</Translation>,
  },
  {
    selector: '.variables__withdrawal',
    content: () => <Translation>{(t) => <Trans t={t} i18nKey="tour.withdrawal">
        The amount of money taken from the investment every month, in order to maintain your desired lifestyle.
        <p>
          If you will still provide a steady income during the selected period (in years) then you might
          want to set the withdrawal value to a negiatve sum, because if you will earn more than you spend,
          then what's left can be added to your investements.
        </p>
      </Trans>
    }</Translation>,
  },
  {
    selector: '.variables__inflation',
    content: () => <Translation>{(t) => t("tour.inflation")}</Translation>,
  },
  {
    selector: '.variables__tax',
    content: () => <Translation>{(t) => t("tour.tax")}</Translation>,
  },

// tabs
  {
    selector: '.CreateTabBtn',
    content: () => <Translation>{(t) => t("tour.CreateTabBtn")}</Translation>,
  },

// table
  {
    selector: 'th[cell-name="addedSpent"]',
    content: () => <Translation>{(t) => t("tour.addedSpentLabel")}</Translation>,
  },
]

const ProjectionsTour = ({ show }) => {
  const [isTourOpen, setIsTourOpen] = useState()

  const onScreenSteps = steps.filter(({ selector }) =>
    selector === ''
      ? true
      : document.querySelector(selector)
        ? !!document.querySelector(selector).clientWidth
        : false
  )

  useEffect(() => {
    document.body.style.overflow = isTourOpen ? 'hidden' : 'auto'
  }, [isTourOpen])

  useEffect(() => {
    // wait until the page was fully loaded
    setTimeout(() => {
      setIsTourOpen( !persist.tour() )
    }, 200)
  }, [])

  useEffect(() => {
    show && setIsTourOpen(true)
  }, [show])

  const onTourClose = () => {
    setIsTourOpen(false)
    persist.tour(true) // save satet to localstorage
  }

  return <StyledTour
    steps={onScreenSteps}
    isOpen={!!isTourOpen}
    rounded={10}
    maskSpace={20}
    showNumber={false}
    onRequestClose={onTourClose}
    lastStepNextButton={<StyledTourDoneButton>Done</StyledTourDoneButton>}
  />
}

export default React.memo(ProjectionsTour)