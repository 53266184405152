import styled, {th} from "@xstyled/styled-components"
import { Tabs } from 'components/atoms'
import media from 'styles/mq'

const BG = 'transparent'

export const Projection = styled.div`
  --side-gap: 1vw; //${th.space('medium')};

  display: flex;
  min-height: 100vh;
  background: ${BG};
  // background: 0 0 / 100% no-repeat url('https://s3-eu-west-1.amazonaws.com/future-fortune.com/bg.webp');
  padding-bottom: var(--side-gap);

  > .ForecastChart{
    flex: 3;
    padding: var(--side-gap);
    margin-top: headerHeight;
    background: white;
   // background: linear-gradient(to bottom, white 30%, transparent 200%);
   // box-shadow: 0 8px 5px -5px rgb(0 0 0 / 10%);
    border-bottom-left-radius: 3;
  }

  .tabs{

  }

  ${media.below("mobile")`
    display: block;

    > .ForecastChart{
      margin-top: 3;
      height: 80vh;
    }
  `}
`

export const GroupTabs = styled(Tabs)`
  display: flex;
  align-items: center;
  height: headerHeight;
  background: white;
  counter-reset: idx;
  padding-left: var(--side-gap);

  ${media.below("mobile")`
    display: none;
  `}

  .tabs__tab{
    --inner-shadow-size: 3px;
    margin: small;
    margin-left: 0;
    height: 70%;
    //min-width: 90px;
    padding: .5em 1em;
    color: var(--color, grey);
    border-radius: 50px;
    font-size: 1.2em;
    font-weight: 500;
    user-select: none;
    transition: .2s ease-out;
    box-shadow: 0 0 0 var(--inner-shadow-size) inset var(--color,grey);

    > span::after{
      counter-increment: idx;
      content: " "counter(idx);
    }

    &:only-of-type{
      button{ display: none; }
    }

    &:hover{
      --inner-shadow-size: 4px;
    }

    &[data-active] {
      color: white;
      --inner-shadow-size: 18px;
    }

    // remove-tab button
    button{
      margin: 0 -.5em 0 1em; // half tab side padding
      background: none;
      color: inherit;

      &:hover{
        background-color: white;
        color: black;
      }
    }
  }
`

export const CreateTabBtn = styled(Tabs.Tab)`
  display: grid;
  place-items: center;
  // width: ${th.space('large')};
  border: 0;
  outline: none;
  width: 2.2em;
  height: 2.2em;
  font-size: 1.4em;
  border-radius: 50%;
  cursor: pointer;
  background: none;
  color: purple;
  transition: .12s;

  &[data-disable]{
    opacity: .5;
    pointer-events: none;
  }

  &:hover{
    background-color: lightpurple;
  }
`

export const Groups = styled.section`
  display: flex;
  flex-flow: column;
  flex: 5;

  .tabs__content{
    flex: 1;
    padding: var(--side-gap);
    padding-bottom: 0;
    position: relative;

    .variablesWrap,
    .ForecastGrid{
      --neum: 6px;
      height: fit-content;
      background: white;

      padding: small;
      border-radius: 3;
    /*
      ${media.above("mobile")`
        box-shadow:  var(--neum) var(--neum) calc(var(--neum)*2) #00000011;
        //1 calc(var(--neum)*-1) calc(var(--neum)*-1) calc(var(--neum)*2) white;
      `}
    */
    }

    ${media.above("mobile")`
      box-shadow: 0 8px 5px -5px rgb(0 0 0 / 10%) inset;

      &::before{
        --size: calc(10px + 1vmin);
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: var(--size);
        height: var(--size);
        background: radial-gradient(circle at bottom left, transparent 65%, rgba(0,0,0,.05) 68%, white 72%);
      }
    `}
  }
`

