import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as Styled from './Variables.styles'
import { Slider } from 'components/atoms'
import { viewports } from 'styles/mq'

let updateDebounce;
const MOBILE_WIDTH = parseInt(viewports.mobile)

const Variables = ({ vars, setVars, toggledVars, setToggledVars }) => {
  const { t:_t } = useTranslation()
  const t = key => _t("variables." + key)

   // sets the currently selected varable from a group
  const setVarGroup = ( name, rel ) => e => {
    // allow the CSS transition to finish
    const viewportWidth = document.documentElement.clientWidth
    const delay = viewportWidth < MOBILE_WIDTH ? 0 : 250
    setTimeout( setToggledVars, delay, lastValue => ({ ...lastValue, [name]:rel }) )
  }

  // only used for a single variable within a group
  const SubVariableRadio = useMemo(() => ({ children, name, rel, title }) =>
    <Styled.SubVariableRadio htmlFor={`${name}-${rel}`}>
      <h4 title={title}>{_t(rel)}</h4>
      {children}
    </Styled.SubVariableRadio>
  , [])

  // group component
  const Variable = useMemo(() => ({ children, transKey, ...rest }) => {
    return <Styled.Variable {...rest}>
      <h3><span title={t(`${transKey}.labelTitle`)}>{t(`${transKey}.label`)}</span></h3>
      {
        children.length
          ? children.map(({props:{name, rel}}) => <>
            <input
              key={`${name}-${rel}`}
              type='radio'
              id={`${name}-${rel}`}
              name={name}
              defaultChecked={toggledVars[name] == rel}
              onChange={setVarGroup(name, rel || '')} />

            <label key={`label-${name}-${rel}`} htmlFor={`${name}-${rel}`}>{_t(rel)}</label>
            </>)
          : null
      }
      <div className='variableWrap'>{children}</div>
    </Styled.Variable>
  }
  , [toggledVars])

  const updateValue = (label, subgroup) => value => {
    clearTimeout(updateDebounce)
    updateDebounce = setTimeout(() => {
      updateDebounce = false

      setVars(lastValue => ({
        ...lastValue,
        [label]: subgroup
          // for variables with subgroup:
          ? {
            ...lastValue[label],
            [subgroup]: value.length > 1 ? value : value[0]
          }
          : value[0]
      }))

    }, 200)
  }

  if( vars && !Object.keys(vars).length )
    return null

  return <Styled.Wrapper className="variables">
    <Variable className='variables__years' transKey="years">
      <Slider value={vars.duration} min={5} max={60} step={1} onChange={updateValue('duration')} />
    </Variable>

    <Variable className='variables__capital' transKey="starting_capital">
      <Slider value={vars.capital} min={50000} max={10000000} step={10000} onChange={updateValue('capital')} />
    </Variable>

    <Variable className='variables__return' transKey="yearly_return">
      <SubVariableRadio name='returnRate' rel='fixed'>
        <Slider value={vars.returnRate.fixed} min={0} max={40} step={.5} suffix='%' onChange={updateValue('returnRate', 'fixed')} />
      </SubVariableRadio>
      <SubVariableRadio name='returnRate' rel='random'>
        <Slider value={vars.returnRate.random} min={-50} max={50} suffix='%' onChange={updateValue('returnRate', 'random')} />
      </SubVariableRadio>
    </Variable>

    <Variable className='variables__withdrawal' transKey="monthly_withdrawal">
      <SubVariableRadio name='monthlyWithdrawal' rel='fixed'>
        <Slider value={vars.monthlyWithdrawal.fixed} min={-5000} max={20000} step={100} onChange={updateValue('monthlyWithdrawal', 'fixed')} />
      </SubVariableRadio>
      <SubVariableRadio name='monthlyWithdrawal' rel='random'>
        <Slider value={vars.monthlyWithdrawal.random} min={-5000} max={20000} step={100} onChange={updateValue('monthlyWithdrawal', 'random')} />
      </SubVariableRadio>
      <SubVariableRadio name='monthlyWithdrawal' rel='ratio' title={t(`monthly_withdrawal.ratioTitle`)}>
        <Slider value={vars.monthlyWithdrawal.ratio} min={0} max={10} step={0.1} suffix='%' onChange={updateValue('monthlyWithdrawal', 'ratio')} />
      </SubVariableRadio>
    </Variable>

    <Variable className='variables__inflation' transKey="inflation">
      <SubVariableRadio name='inflation' rel='fixed'>
        <Slider value={vars.inflation.fixed} min={-1} max={10} step={.5} suffix='%' onChange={updateValue('inflation', 'fixed')} />
      </SubVariableRadio>
      <SubVariableRadio name='inflation' rel='random'>
        <Slider value={vars.inflation.random} min={-5} max={20} step={.5} suffix='%' onChange={updateValue('inflation', 'random')} />
      </SubVariableRadio>
    </Variable>

    <Variable className='variables__tax' transKey="tax">
      <SubVariableRadio name='tax' rel='fixed'>
        <Slider value={vars.tax.fixed} min={0} max={50} step={.5} suffix='%' onChange={updateValue('tax', 'fixed')} />
      </SubVariableRadio>
      <SubVariableRadio name='tax' rel='random'>
        <Slider value={vars.tax.random} min={0} max={50} step={.5} suffix='%' onChange={updateValue('tax', 'random')} />
      </SubVariableRadio>
    </Variable>

  </Styled.Wrapper>
}

export default React.memo(Variables)