{
  "fixed": "fixed",
  "random": "random",
  "ratio": "ratio",
  "future": "future",
  "resetButton": {
    "label": "reset",
    "title": "Clear local storage and all current projections"
  },
  "what_is_this": "what is this",
  "credit": "by <1>Yair Even Or</1>",
  "newGroupButton": {
    "title": "Add New Projection (up to {{MAX_GROUPS}})"
  },

  "calculations": {
    "title": "How Things Are Calculated",
    "random_return_rate": "Random Return Rate",
    "random_return_rate__info": "When selecting a random return rate, the distribution is \
                                <0>not uniform but \"normal\" with a fat tail</0> - \
                                <1>See distribution chart with code</1>",
    "year_end_total": "Year End Total",
    "yearly_withdrawal": "Yearly Withdrawal",
    "tax": "Yearly Tax Paid"
  },

  "tldr": "<0>Welcome to <0>Future-Fortune</0> - A financial calculator which was build to provide comparable projections \
          regarding your financial savings using various parameters, which I've concluded are the minimum necessary for a \
          rough estimation to the pile of money will you have after a certain amount of years (by investing it all). \
          </0> \
          <1>This savings calculator aim, at least for me, to roughly plan my financial future, knowing when will I be free \
          and have enough money to retire, living a life of financial independence and be a happier person by feeling safe I \
          will have enough money saved to last me through the years, as I want to retire as early as possible.</1>\
          <2>✉️ Please write me for anything: <1>Yair Even Or</1></2>",

  "comments": {
    "title": "Lets Talk",
    "subtitle": "Have any improvement ideas?\nDo you find this helpul?\nLet me know"
  },

  "variables": {
    "years": {
      "label": "Years Of Investing",
      "labelTitle": "Years of investment ahead"
    },
    "starting_capital": {
      "label": "Starting Capital",
      "labelTitle": "Currency type is not important"
    },
    "yearly_return": {
      "label": "Yearly Return",
      "labelTitle": "Total investments yearly return"
    },
    "monthly_withdrawal": {
      "label": "Monthly Withdrawal",
      "labelTitle": "Amount of cash required to maintain your lifestyle",
      "ratioTitle": "Withdrawal percentage from total capital, per year. Not affected by inflation"
    },

    "inflation": {
      "label": "Inflation",
      "labelTitle": "Affects Withdrawal sum"
    },
    "tax": {
      "label": "Tax",
      "labelTitle": "Tax which applies to your type of investments"
    }
  },

  "table": {
    "headers": {
      "year": {
        "label": "Year",
        "labelTitle": ""
      },
      "withdrawal": {
        "label": "Withdrawal",
        "labelTitle": "Calculated from year's starting capital"
      },
      "ratio": {
        "label": "ratio",
        "labelTitle": "Expense-To-Capital ratio, relative to year's starting capital"
      },
      "tax": {
        "label": "Tax",
        "labelTitle": "Tax paid for the sum withdrawn through the year"
      },
      "addedSpent": {
        "label": "Added/Spent",
        "labelTitle": "Special income or expense during this year"
      },
      "return": {
        "label": "Return Rate",
        "labelTitle": "Return rate per year"
      },
      "total": {
        "label": "Year End Total",
        "labelTitle": ""
      }
    },
    "cells": {
      "withdrawal": {
        "title": "Inflation of {{inflation}}%"
      },
      "total": {
        "title": "Yearly return rate of {{returnRate}}%"
      }
    }
  },


  "tour": {
    "welcome": "<0>Welcome To <0>Future Fortune</0> Financial Calculator</0> \
      <1> \
        <0>Plan your retirement</0> with ease, projecting different future scenarios, living off \
        your investments by a monthly withdrawal \
      </1> \
      <2>👉 Best used on desktop computer 🖥️</2> \
      <3> \
        🐔\u00a0 \
        This website does not use a server \
        therefore the data you provide cannot be stored. \
        There is no sign-in so your identity is unknown \
      </3>",

    "var_years": "Select the number of <1>years</1> to run the projection for. \
        <3/> \
        <4> \
          You can use your mousewheel <1>🖱️</1> to fine-tune values by hovering the slider and moving the wheel \
        </4>",

    "capital": "How much money you're starting with - the currency does not matter.",

    "return": "Estimated investment return rate, in percentages, per year. \
        <1> \
          Choose <1>Random</1> option to set a range of possible values so <1>each year</1> will use a random value, \
          which mimics the nature of some real-world investements \
        </1>",

    "withdrawal": "The amount of money taken from the investment every month, in order to maintain your desired lifestyle. \
        <1> \
          If you will still provide a steady income during the selected period (in years) then you might \
          want to set the withdrawal value to a negiatve sum, because if you will earn more than you spend, \
          then what's left can be added to your investements. \
        </1>",

    "inflation": "The inflation rate has a profound effect on the yearly withdrawals and the total outcome",

    "tax": "Capital tax for investements gains",

    "CreateTabBtn": "Create a new set of variables to compare between different projections",

    "addedSpentLabel" : "For each year a \"special\" sum can be added or deducted, for example a large unexpected spending or (hopefully) a gift from a rich relative"
  }
}
