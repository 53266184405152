import { generateMedia } from 'styled-media-query' // https://github.com/morajabi/styled-media-query

export const viewports = {
  mobile: "460px",
  l     : "1200px",
  xl    : "1800px",
  huge  : "2200px"
}

const media = generateMedia(viewports)

media.below = media.lessThan
media.above = media.greaterThan

export default media