import styled, {th} from '@xstyled/styled-components'
import media from 'styles/mq'


const innerSidePad = '1em';

export const Wrapper= styled.div`
  border-spacing: 1em 1em;

  ${media.greaterThan("huge")`
    display: table;
  `}

  html:lang(he) &{
    direction: rtl;
  }
`

export const Variable = styled.div`
  position: relative;
  // display: flex;
  // align-items: center;

  ${media.lessThan('huge')`
    --flip-y: 1;
    &:not(:first-child){ margin-top: 3em; }
  `}

  ${media.lessThan('l')`
    &:not(:first-of-type){
      margin-top: 5;
    }
  `}

  ${media.greaterThan("huge")`
    display: table-row;
  `}

  h3{
    margin: 0 0 1.5em;
    font-size: 3;
    font-weight: 500;
    display: inline-block;

    ~ label{
      float: right;
      padding: 1 2;
      cursor: pointer;
      text-transform: capitalize;
      font-weight: 500;
      border-radius: 2;
      color: blacks.2;

      html:lang(he) &{
        float: left;
      }

      &:hover{
        color: inherit;
      }
    }

    ${media.greaterThan("huge")`
      display: table-cell;

      ~ label{
        display: none;
      }
    `}

    ${media.lessThan('xl')`
      font-size: 2;
      margin-bottom: 2em;

      ~ label{
        margin-top: -${th.space(1)};
      }
    `}

    + .variableWrap{
      padding: 1.5em ${innerSidePad};

      ${media.lessThan('huge')`
        padding: 0;
      `}
    }
  }

  .variableWrap{
    &:only-of-type{
      overflow: visible;
    }
  }

  ${media.greaterThan("huge")`
    > *{
      display: table-cell;
      vertical-align: middle;
    }
  `}

  > input{
    display: none;
    &:checked{
      &:nth-of-type(1){
        ~ label:nth-of-type(1){ background-color:lightblue; color:inherit; }
        ~ .variableWrap{
          overflow: visible;
          > label:nth-child(1){
            --border-color: var(--border-color-seleted);
            --is-selected: 1;
          }
        }
      }

      &:nth-of-type(2){
        ~ label:nth-of-type(2){ background-color:lightblue; color:inherit; }
        ~ .variableWrap{
          overflow: visible;
          > label:nth-child(2){
            --border-color: var(--border-color-seleted);
            --is-selected: 1;
          }
        }
      }

      &:nth-of-type(3){
        ~ label:nth-of-type(3){ background-color:lightblue; color:inherit; }
        ~ .variableWrap{
          overflow: visible;
          > label:nth-child(3){
            --border-color: var(--border-color-seleted);
            --is-selected: 1;
          }
        }
      }
    }
  }

  .variableWrap{
    gap: ${innerSidePad};
    overflow: hidden;

    ${media.greaterThan('huge')`
      display: flex;
    `}
  }

  .variableContent{
    padding: 1.5em 1.5em;
  }
`

export const Label = styled.label`
`

export const SubVariableRadio = styled.label`
  --border-color: ${th.color('lightblue')};
  --border-color-seleted: ${th.color('green500')};

  // margin-right: ${innerSidePad};
  padding: 1em ${innerSidePad} 2em;
  flex: calc(var(--is-selected, .33) * 3);
  border: 3px solid var(--border-color, transparent);
  border-radius: 2;
  cursor: pointer;
  transition: .22s ease-in-out;

  ${media.lessThan('huge')`
    display: inline-block;
    width: calc(var(--is-selected, 0) * 100%);
    flex: calc(var(--is-selected, 0) * 3);
    padding: 0;
    border: 0;
  `}

  ${media.lessThan('mobile')`
    display: var(--is-selected, none);
  `}

  &:hover{
    --border-color: ${th.color('grays.0')};
  }

  h4{
    text-transform: capitalize;
    min-width: 80px;
    margin: 0 0 2em 0;
    font-size: 2;
    color: blacks.1;

    ${media.lessThan('huge')`
      display: none;
      /* font-size: 1;
      position: absolute;
      right: 0;
      top: 2px;
      min-width: unset;
      opacity: calc(var(--is-selected, .5) * 1); */
    `}

    ~ *{
      transition: inherit;
      opacity: var(--is-selected, 0);
      min-width: 80px;
      z-index: var(--is-selected, -1); // when subgroup is not selected, the slider should not be accessible by mouse
    }
  }

  ${media.lessThan('huge')`
    &:nth-of-type(2){
      h4{ right: 35px; }
    }

    &:nth-of-type(3){
      h4{ right: 86px; }
    }
  `}

  // selected state
  /*
  &::after{
    content: '';
    position: absolute;
    z-index: calc(var(--is-selected, 10) - 2);
    top: 0; right: 0; bottom: 0; left: 0;
    border: 3px solid var(--border-color, transparent);
    border-radius: 2;
  }
  */
`