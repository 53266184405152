import styled, {th} from '@xstyled/styled-components'
import media from 'styles/mq'

export const ForecastGrid = styled.div`
  &.randomReturnRate{
    --show-return-rate: block;
  }

  table{
    --cell-pad: .8em;

    width: 100%;
    border-spacing: 0;
    position: relative;
    border-radius: 2;
    border: 1px solid transparent;
    border-collapse: collapse;
    font-size: 1.2em;
    font-weight: 300;

    [data-percentage]{
      &::after{ content:'%'; font-size:.9em; opacity:.77; }
    }

    th, td{
      padding: 0 var(--cell-pad);

      &[cell-name="return"]{
        display: var(--show-return-rate, none);
      }
    }

    th{
      position: sticky;
      z-index: 1;
      top: -1px;
      background: white;
      padding: .5em var(--cell-pad);
     // background-color: lightpurple;
      background: linear-gradient(to bottom, ${th.color('lightblue')}, ${th.color('lightblue')} 30%);
      color: black;
      text-align: center;
      font-weight: 400;
      text-transform: capitalize;

      &:first-child{
        border-top-left-radius: 4;
      }

      &:last-child{
        text-align: right;
        border-top-right-radius: 4;
      }
    }

    td{
      border-top: 1px solid;
      border-color: rgba(0,0,0,.1);
      text-align: right;

      &[data-sign="positive"]{ color: green; }
      &[data-sign="negative"]{ color: red; }

      &:last-child{
        font-weight: 400;
        transition: 80ms;
        transform-origin: center right;
      }

      &:nth-child(1){ opacity: .5; }
      &:nth-child(1),
      &:nth-child(3){ text-align: center; }

      &[cell-name="ratio"]{
        &[data-infinite="true"]{
          font-size: 2em;
          line-height: 1;
          opacity: .5;
          &::after{ content:unset; }
        }
      }

      &[cell-name="addedSpent"]{
        width: 1%;
        padding: 0;

        &:hover{
          input[type='']{ padding-right: 1.5em;
            &:not(:placeholder-shown){
              + button{
                opacity: 1;
              }
            }
          }
        }
      }

      > div{
        position: relative;
      }
    }

    tbody tr{
      &:hover{
        td{
          &:nth-child(1){ opacity: 1; font-weight: 500; }
         // &:last-child{ font-size: 1.2em; }
        }
      }
    }

    input{
      width: 100px;
      border-color: transparent;
      background: transparent;
      text-align: inherit;
      font: inherit;
      color: inherit;
      box-sizing: border-box;
      transition: 80ms;

      // hide "number" input up/down arrows whih cause blur when clicked
      // https://stackoverflow.com/a/27935448/104380
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:focus{
        transition: 0s;
       // text-align: center;
      }

      &:placeholder-shown + button{
        opacity: 0;
        pointer-events: none;
      }

      + button{
        --size: 15px;
        position: absolute;
        border: none;
        display: block;
        width: var(--size);
        height: var(--size);
        line-height: calc(var(--size) + 2px);
        font-weight: 700;
      //  font-size: calc(var(--size) - 3px);
      //  border-radius: 50%;
        top: 0;
        bottom: 0;
        right: 5px;
        margin: auto;
        background: none;
        color: black;
        padding: 0;
        outline: none;
        cursor: pointer;
        opacity: 0;
        transition: .1s;

        &:hover{
          color: red;
        }
      }
    }

    ${media.lessThan('mobile')`
      --cell-pad:  .5em;
      --show-return-rate: none;
      font-size: 1em;

      th, td{
        &:not(:first-child){
          text-align: right;
        }
      }

      th[cell-name="year"]{
        font-size: 0;
      }

      th[cell-name="withdrawal"]{
        width: 1%;
      }

      [cell-name="addedSpent"]{
        display: none;
      }
    `}
  }
`