import React, { createContext, useContext, useEffect, useState } from 'react'
import getProjections from 'components/organisms/Projection/getProjections'
// import varsInitialValues from './initialValues.json'
import { colors } from 'styles/theme'
import { mergeDeep, persist, parseURL, setURL, isObject, getToggledVars } from 'utils'

const { pallete } = colors

const getGroupDefaultProps = id => ({
  color: pallete[+id - 1] || '#000',
  vars: {
    "duration": 30,
    "capital": 1000000,
    "monthlyWithdrawal": {
      "fixed": 2000,
      "random": [1000, 3000],
      "ratio": 1
    },
    "returnRate": {
      "fixed": 8,
      "random": [-5, 20]
    },
    "inflation": {
      "fixed": 2.5,
      "random": [-1, 4]
    },
    "tax": {
      "fixed": 25,
      "random": [15, 35]
    }
  },
  addedSpent: {},
  toggledVars: {
    monthlyWithdrawal: 'fixed',
    returnRate       : 'fixed',
    inflation        : 'random',
    tax              : 'fixed',
  }
})

/**
 *
 * @param {Obejct} URLParams key/value variables (only the toggled ones + "toggledVars" map)
 * @param {Number} id group's id number
 * @returns a proper "group" Object
 */
const urlParamsToGroup = (URLParams, id) => {
  if( !URLParams || Object.keys(URLParams) < 2 || !URLParams.toggledVars ) return {}

  const group = getGroupDefaultProps(id)
  group.toggledVars = URLParams.toggledVars
  group.addedSpent = URLParams.addedSpent || {}
  group.color = '#000'

  for( let k in group.vars ){
    // consider normalizing the values within the range of default values.
    // currently choosing not to do so since not expecing user to mess with URL
    let initialValue = group.vars[k]

    if( isObject(group.vars[k]) ){
      let initialValue = group.vars[k][group.toggledVars[k]]

      if( k in URLParams && !isNaN(URLParams[k]) )
        group.vars[k][group.toggledVars[k]] = URLParams[k]
    }
    else
      group.vars[k] = isNaN(URLParams[k]) ? group.vars[k] : URLParams[k]
  }

  return group
}

export const ProjectionsProvider = ({ children }) => {
  const [projections, setProjections] = useState({})
  const [activeGroup, setActiveGroup] = useState()
  const [groups, setGroups] = useState({})
  const [showTour, setShowTour] = useState(false)

  const setVars = (cb) => {
    setGroups(lastValue => ({
      ...lastValue,
      [activeGroup]: {
        ...lastValue[activeGroup],
        vars: cb(lastValue[activeGroup].vars)
      }
    }))
  }

  const setToggledVars = (cb) => {
    setGroups(lastValue => ({
      ...lastValue,
      [activeGroup]: {
        ...lastValue[activeGroup],
        toggledVars: cb(lastValue[activeGroup].toggledVars)
      }
    }))
  }

  const setAddedSpent = (cb) => {
    setGroups(lastValue => ({
      ...lastValue,
      [activeGroup]: {
        ...lastValue[activeGroup],
        addedSpent: cb(lastValue[activeGroup].addedSpent)
      }
    }))
  }

  const newGroup = id => {
    setProjections(lastState => ({ ...lastState, [id]:[] }))
    setGroups(lastState => ({
      ...lastState,
      [id]: getGroupDefaultProps(id)
    }))
    setActiveGroup(id)
  }

  const removeGroup = id => {
    setProjections(lastState => {
      const newState = mergeDeep({}, lastState)
      delete newState[id]
      return newState
    })

    setGroups(lastState => {
      const newState = mergeDeep({}, lastState)
      delete newState[id]

      if( activeGroup == id ){
        setActiveGroup( Object.keys(newState)[0] )
      }

      return newState
    })
  }

  /**
   * Update the URL when a group tab changes
   */
  useEffect(() => {
    setURL(groups, activeGroup)
  }, [activeGroup])

  /**
   * When any group changes:
   * 1. Calculate projections for that group
   * 2. Save state in the URL
   * 3. Save state to localstorage
   */
  useEffect(() => {
    const group = groups[activeGroup]

    if( group ){
      setProjections(lastValue => ({
        ...lastValue,
        [activeGroup]: getProjections(group)
      }))

      persist.set(groups, activeGroup)
      setURL(groups, activeGroup)
    }
  }, [groups])

  useEffect(() => {
    const persistedGroups = persist.get() || {}
    const groupsLen = Object.keys(persistedGroups).length
    const URLParams = parseURL()
    const URLParamsGroupNumber = groupsLen + 1;
    const URLGroup = urlParamsToGroup(URLParams, URLParamsGroupNumber)

    const newProjections = {}

    // check if the URL group (derrived from the params) exists already in the persisted groups data
    const URLGroupInGroups = Object.keys(URLGroup).length
      ? Object.values(persistedGroups).some((v) => {
        let toggledPersistedGroupVars = getToggledVars(v.vars, v.toggledVars),
            toggledURLVars            = getToggledVars(URLGroup.vars, URLGroup.toggledVars)

        return JSON.stringify(toggledPersistedGroupVars) == JSON.stringify(toggledURLVars)
      })
      : false;

    const shouldUseURLParams = URLGroup && URLGroup.vars && !URLGroupInGroups

    // naive check "URLGroup" is valid
    if( shouldUseURLParams ){
      // add the URL "group" to the end of the persistedGroups
      persistedGroups[URLParamsGroupNumber] = URLGroup
    }
    else if( groupsLen == 0 )
      // if no persisted groups available and no url param, create a first group
      persistedGroups["1"] = getGroupDefaultProps(1)

    if( persistedGroups ){
      for( let k in persistedGroups ){
        newProjections[k] = getProjections(persistedGroups[k])
      }

      setGroups(persistedGroups)
      setProjections(newProjections)
      setActiveGroup( shouldUseURLParams ? URLParamsGroupNumber : Object.keys(persistedGroups)[0] )
    }
  }, [])

  /**
   * Removes all groups
   */
  const reset = () => {
    setProjections({ "1":[] })
    setGroups({ "1": getGroupDefaultProps(1) })
    setActiveGroup(1)
  }

  return <ProjectionsContext.Provider value={{
      reset,
      projections,
      activeGroup,
      setActiveGroup,
      groups,
      newGroup,
      removeGroup,
      setVars,
      setToggledVars,
      setAddedSpent,
      showTour,
      setShowTour
    }}>
    {children}
  </ProjectionsContext.Provider>
}

export const ProjectionsContext = createContext(null)
export const useProjectionsContext = () => useContext(ProjectionsContext)