import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { cx } from 'utils'
import ForecastGridRow from './ForecastGridRow'
import * as Styled from './ForecastGrid.styles'
import COL_NAMES from './columnsNames'

const ForecastGrid = ({ className, projection, addedSpent, setAddedSpent }) => {
  const { t:_t } = useTranslation()
  const t = key => _t("table.headers." + key)

  const onAddedSpent = ({ value, name:year }) => {
    if( addedSpent[year] != +value )
      setAddedSpent(lastValue => ({ ...lastValue, [year]:+value }))
  }

  return <Styled.ForecastGrid className={cx('ForecastGrid', className)}>
    <table>
      <thead>
        <tr>
          {['year', 'withdrawal','ratio','tax','addedSpent','return','total'].map(name =>
            <th key={name+"header"}
                cell-name={COL_NAMES[name]}
                scope="col"
                title={t(`${name}.labelTitle`) || undefined}>
              {t(`${name}.label`)}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        { projection.map((rowData, i) => <ForecastGridRow key={i} {...rowData} onAddedSpent={onAddedSpent} /> )}
      </tbody>
    </table>
  </Styled.ForecastGrid>
}

export default ForecastGrid