
import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useTour } from 'hooks'
import { persist } from 'utils'
import { useProjectionsContext } from 'store/context.js'
import * as Styled from './MainHeader.styles'

const MainHeader = () => {
  const { t } = useTranslation()

  const { reset } = useProjectionsContext()
  const { showTour } = useTour()

  const resetApp = () => {
    persist.clearGroups()
    reset()
  }

  return (
    <Styled.Header>
      <Styled.ResetButton onClick={resetApp} title={t("resetButton.title")}>{t("resetButton.label")}</Styled.ResetButton>
      <Styled.TourButton onClick={showTour}>{t("what_is_this")}</Styled.TourButton>
      <span><Trans t={t} i18nKey="credit">
        By <a href="mailto:contact@future-fortune.com">Yair Even Or</a>
      </Trans></span>
    </Styled.Header>
  )
}

export default MainHeader