import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Chart from 'react-apexcharts'
import styled from '@xstyled/styled-components'
import media from 'styled-media-query' // https://github.com/morajabi/styled-media-query
import { cx } from 'utils'
import options from './chartConfig'

// import { useTranslation } from "react-i18next"
// const { t } = useTranslation()

const Styled = {
  ForecastChart: styled.div(props => `
    max-height: 100vh;
    position: sticky;
    top: 0;

    .apexcharts-toolbar{
      display: none;
    }

    &[data-labels-gap]{
      .apexcharts-xaxis-label{ display:none }
    }

    ${[2,3,5].map(v => `
      &[data-labels-gap="${v}"]{
        .apexcharts-xaxis-label:nth-child(${v}n){ display:revert }
      }
      `).join('')
    }
  `)
}


const ForecastChart = ({ groups, projections, className }) => {
  const { t } = useTranslation()
  const [projectionsValues, setProjectionsValues] = useState([])
  const [labelsGap, setlabelsGap] = useState()
  const DOM = {
    ForecastChart: useRef()
  }

  const resizeObserver = useMemo(() => new ResizeObserver(([entry]) => {
    const maxYears = projectionsValues.reduce((acc, v) => v.length > acc ? v.length : acc, 0)
    const ratio = entry.contentRect.width / maxYears
    let showLabelEvery

    // console.log( ratio, [entry.contentRect.width , maxYears] )
    if( ratio < 20 )
      showLabelEvery = 2
    if( ratio < 15 )
      showLabelEvery = 3
    if( ratio < 12 )
      showLabelEvery = 5

    setlabelsGap(showLabelEvery)
  }), [projectionsValues, setlabelsGap])

  useEffect(() => {
    resizeObserver.disconnect()
    DOM.ForecastChart.current && resizeObserver.observe(DOM.ForecastChart.current)
    return () => DOM.ForecastChart.current && resizeObserver.unobserve(DOM.ForecastChart.current)
  }, [projectionsValues])

  useEffect(() => {
    const newValues = Object.values(projections)
    // const isBarChart = projectionsValues.length != newValues.length && newValues.length == 1
    setProjectionsValues( newValues )
  }, [projections])

  if( !projectionsValues.length ) return <Styled.ForecastChart className={cx('ForecastChart', className)}/>

  // get projection with maximum years
  const maxYears = projectionsValues.reduce((acc, v) => v.length > acc ? v.length : acc, 0)
  const series = projectionsValues.map((p,i) => ({ name:`${t('future')} ${i+1}`, data:p.map(d => d.total) }))

  if( false && document.querySelector('.apexcharts-canvas') ){
    ApexCharts.exec('projections-chart', 'updateOptions', {
      // set the colors of the groups (tabs)
      colors: Object.values(groups).map(({color}) => color),
      xaxis: {
        categories: [...Array(maxYears)].map((n,i) => i + 1)
      }
    })
  }

  // options.labels = [...Array(maxYears)].map((n,i) => i + 1)

  return <Styled.ForecastChart className={cx('ForecastChart', className)} data-labels-gap={labelsGap} ref={DOM.ForecastChart}>
    <Chart
      options={options}
      series={series}
      type="line"
      width="100%"
      height="100%"
    />
  </Styled.ForecastChart>
}

export default ForecastChart