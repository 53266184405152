{
  "fixed": "קבוע",
  "random": "טווח רנדומלי",
  "ratio": "יחס",
  "future": "עתיד",
  "resetButton": {
    "label": "איפוס",
    "title": "מחיקת כל המידע שהוזן ונשמר בדפדפן והתחלה מחדש"
  },
  "what_is_this": "מדריך שימוש",
  "credit": "מאת <1>יאיר אבן אור</1>",
  "newGroupButton": {
    "title": "הוספת תחזית חדשה\n(עד {{MAX_GROUPS}} תחזיות)"
  },

  "calculations": {
    "title": "איך החישוב נעשה",
    "random_return_rate": "תשואה רנדומלית על פני טווח",
    "random_return_rate__info": "כשבוחרים תחזית לפי טווח תשואה רנדומלי, ולא ערך קבוע, החישוב מתבצע לפי <0>עקומת פיזור נורמלית</0> עם נטיה קלה לצד האופטימי. <1>לצפיה בקוד ובגרף</1>",
    "year_end_total": "הון בסוף שנה",
    "yearly_withdrawal": "סך משיכה בשנה",
    "tax": "מס לשנה"
  },

  "tldr": "<0> \
          ברוכים הבאים למחשבון עתיד ההשקעות הפיננסי שנבנה במטרה ליצור תחזיות פיננסיות להשקעות אישיות ולהשוות בניהן בנוחות, תוך כדי התאמת הפרמטרים לכל קבוצת השוואה. \
          </0> \
          <1> \
            מחשבון העתיד הפיננסי מציג תחזית לפי מספר שנות ההשקעה שהוזנו והתאמת הפרמטרים לפי סגנון השקעה וחיים כלשהם, ובכך עוזר לדעת אם ומתי אפשרי לפרוש ולחיות מתיק החסכונות, למי שמעוניין בכך. \
          </1> \
          <2> \
            אשמח אם תכתבו לי:\
            <1>\
            יאיר אבן אור\
            </1>\
          </2>",

  "comments": {
    "title": "דיון",
    "subtitle": "יש לכם רעיונות לשיפור?\nהאתר עזר לכם?\nכיתבו לי על זה"
  },

  "variables": {
    "years": {
      "label": "שנות השקעה",
      "labelTitle": "שנות השקעה מעטה והאלה"
    },
    "starting_capital": {
      "label": "הון התחלתי",
      "labelTitle": "סכום התחלתי להרצת תחזית ההשקעה"
    },
    "yearly_return": {
      "label": "תשואה שנתית",
      "labelTitle": "תשואה שנתית חזויה אותה מצפים לקבל"
    },
    "monthly_withdrawal": {
      "label": "משיכה חודשית",
      "labelTitle": "סכרם משיכה מסך כל ההון שנועד לשם מחיה חודשית",
      "ratioTitle": "יחס משיכה מסך ההון לשנה מסויימת. חישוב זה אינו מושפע מאינפלציה"
    },

    "inflation": {
      "label": "אינפלציה",
      "labelTitle": "משפיעה על סכום המשיכה החודשי"
    },
    "tax": {
      "label": "מס",
      "labelTitle": "אחוז על מס על הרווחים - משתנה לפי סוג ההשקעה"
    }
  },

  "table": {
    "headers": {
      "year": {
        "label": "שנה",
        "labelTitle": ""
      },
      "withdrawal": {
        "label": "משיכה",
        "labelTitle": "סכום המשיכה השנתי, מחושב לפי סכום הכסף בתחילת השנה"
      },
      "ratio": {
        "label": "יחס",
        "labelTitle": "יחס משיכה להון בתחילת השנה"
      },
      "tax": {
        "label": "מס",
        "labelTitle": "משולם על רווחים, לפי סכום המשיכה, במידה ויש רווחים באותה שנה"
      },
      "addedSpent": {
        "label": "תוספת/משיכה",
        "labelTitle": "תוספת כסף או משיכה מיוחדים בשנה מסויימת, למשל קנית בית או קבלת ירושה"
      },
      "return": {
        "label": "תשואה",
        "labelTitle": "תשואה לשנה - תלוי בבחירת סוג התחזית במשתנים בצד (תשואה קבועה או משתנה)"
      },
      "total": {
        "label": "הון בסוף השנה",
        "labelTitle": ""
      }
    },
    "cells": {
      "withdrawal": {
        "title": "אינפלציה של {{inflation}}%"
      },
      "total": {
        "title": "{{returnRate}}% תשואה שנתית"
      }
    }
  },

  "tour": {
    "welcome": "<0>\
      ברוכים הבאים ל \
      <0> \
      Future Fortune \
      </0> \
      מחשבון פיננסי</0> \
      <1> \
        .תכנון פיננסי לפי פרמטרים פשוטים על פני מספר שנים מה שיאפשר בתקווה פרישה עם מספיק כסף עבור שארית החיים \
      </1> \
      <2>מומלץ לפתוח כלי זה במחשב בעל שטח תצוגה נדיב 🖥️</2> \
      <3> \
        🐔\u00a0 \
        האתר אינו משתמש בשרת, כל החישובים נעשים אר ורק על מחשב שלך ולכן המידע המוזן לא נשמר בשום מקום. \
        האתר נבנה מטוב ליבי בתור תחביב. \
      </3>",

    "var_years" : "בחר את מספר ה \
      <1>שנים</1> \
      שברצונך לתת תחזית עבורן. \
        <3/> \
        <4> \
          לנוחיותך, ניתן להשתמש בגלגלת העכבר \
          <1>🖱️</1>\
          עבור שינוי ערכים, על ידי השמת סמן העכבר על האזור הגריר \
        </4>",

    "capital": "כמות הכסף ההתחלתית שעליה ניתן להשיג תשואה בהשקעות שונות",

    "return": "תשואה שנתית משוערת, באחוזים \
      <1> \
        בחר את אפשרות ה \
        <1> \
        \"טווח רנדומלי\" \
        </1> \
        להגרלת ערכים רנדומליים בכל שנה המוגבלים בטווח מסויים \
        כדי לייצר סימולציה קרובה יותר למציאות (בקירוב גס) \
      </1>",

    "withdrawal": "סכום הכסף שנפדה כל חודש על מנת לממן את סגנון החיים וצרכים שונים \
        <1> \
          במידה ולאורך התקופה נכנסת עדיין הכנסה כלשהי, יש להתחשב בהכנסה זו ולהפחיתה מסכום המשיכה החודשית החזוי. \
          אם סכום ההכנסה החודשי עולה על סכום המשיכה, יש להזין סכום משיכה חודשית שלילי, והשארית תושקע בחזרה. \
        </1>",

    "inflation": "אינפציה חזויה לאורך שנות הסימולציה",

    "tax": "אחוז המס שיורד בעת מימוש עבור סכום המשיכה החודשי",

    "CreateTabBtn": "צור תחזית חדשה כדי להשווה בין תחזיות בעלות משתנים שונים",

    "addedSpentLabel" : "עבור כל שנה בנפרד ניתן להזין סכום כסף חיובי או שלילי צפוי, למשל קבלת ירושה מדוד עשיר באמריקה או הוצאה גדולה עבור קנית בית"
  }
}
