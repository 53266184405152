import React from 'react'
import { cx } from 'utils'
import { useTranslation } from 'react-i18next'
import NumberInput from '@yaireo/react-number-input'
import COL_NAMES from './columnsNames'

const ForecastGridRow = ({ year, withdrawal, inflation, withdrawalCapitalRatio, taxPaid, taxPerc, total, onAddedSpent, addedSpent, returnRate, className }) => {
  const { t:_t } = useTranslation()
  const t = (key, d) => _t("table.cells." + key, d)

  const clearAddedSpent = () => {
    onAddedSpent({ name:year, value:'' })
  }

  const onAddedSpentEvent = e => {
    // only update if really changed
    if( (addedSpent||'') != e.target.value )
      onAddedSpent(e.target)
  }

  const numberSign = n => n
    ? +n >= 0 ? "positive" : "negative"
    : ''

  return (
    <tr>
      <td cell-name={COL_NAMES.year}>{year}</td>
      <td cell-name={COL_NAMES.withdrawal} title={t(`withdrawal.title`, {inflation})}>{withdrawal.toLocaleString()}</td>
      <td cell-name={COL_NAMES.ratio} data-percentage data-infinite={isNaN(withdrawalCapitalRatio)}>{withdrawalCapitalRatio.toLocaleString()}</td>
      <td cell-name={COL_NAMES.tax} title={`${taxPerc}%`}>{taxPaid.toLocaleString()}</td>
      <td cell-name={COL_NAMES.addedSpent} data-sign={numberSign(addedSpent)}>
        <div>
          <NumberInput autoComplete="off" name={""+year} onBlur={onAddedSpentEvent} placeholder=" " defaultValue={+addedSpent} />
          <button onClick={clearAddedSpent}>&times;</button>
        </div>
      </td>
      <td cell-name={COL_NAMES.return} data-sign={numberSign(returnRate)} data-percentage>{returnRate.toFixed(2).toLocaleString().replace(".00","")}</td>
      <td cell-name={COL_NAMES.total} data-sign={numberSign(total)} title={t(`total.title`, {returnRate:returnRate.toFixed(2).replace('.00', '')})}>{total.toLocaleString()}</td>
    </tr>
  )
}

export default ForecastGridRow