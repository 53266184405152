import React, { StrictMode } from 'react'
import {render} from 'react-dom'
import {ThemeProvider} from '@xstyled/styled-components'
import position from '@yaireo/position'
import theme from './styles/theme'
import GlobalStyle from './styles/global'
import './i18next' // translations system
import { Home } from 'components/pages'
import { titleTooltip } from 'utils'

titleTooltip({
  onShow: function(reference, popper){
    position({
      ref       : reference,
      target    : popper,
      useRaf    : false,
      offset    : [0, 8],
      placement : "center below",
      track     : {scroll:true}
    })
  }
})

// https://stackoverflow.com/a/38229616/104380
if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual';
}

const App = () => (
  <main>
    <Home/>
  </main>
)

render(
  <ThemeProvider theme={theme}>
      <GlobalStyle />
      <StrictMode>
        <App />
      </StrictMode>
  </ThemeProvider>
, document.getElementById('root'))