// Random Distribution (Box–Muller transform)
// https://stackoverflow.com/a/49434653/104380

export default function bmRandom(min, max, skew) {
  let u = 0, v = 0;
  while(u === 0) u = Math.random(); //Converting [0,1) to (0,1)
  while(v === 0) v = Math.random();
  let num = Math.sqrt( -2.0 * Math.log( u ) ) * Math.cos( 2.0 * Math.PI * v )

  num = num / 5.0 + 0.5 // Translate to 0 -> 1

  if (num > 1 || num < 0)
    num = bmRandom(min, max, skew) // resample between 0 and 1 if out of range

  else{
    num = Math.pow(num, skew) // Skew
    num *= max - min // Stretch to fill range
    num += min // offset to min
  }

  return num
}