import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Group, ForecastChart } from 'components/organisms'
import { Tabs, Icon } from 'components/atoms'
import { useProjectionsContext } from 'store/context.js'
import ProjectionsTour from './Tour'
import * as Styled from './Projection.styles'

const MAX_GROUPS = 5;

const Projection = () => {
  const { t } = useTranslation()

  const { showTour, groups, projections, activeGroup, setActiveGroup, newGroup, removeGroup } = useProjectionsContext()

  const groupsKeys = Object.keys(groups)

  // create the first group
  // useEffect(() => {
  //   newGroup(1)
  // }, [])

  const createGroup = () => {
    try{
      const ids = groupsKeys.map(k => +k).sort((a,b) => a - b)
      // find a "free" id: [1,3,4,5] => 2   /  if nothing free, use next one
      const id = (ids.find((v,i) => v != i+1) - 1) || ids.pop() + 1
      newGroup(id)
    }
    catch(err){ console.warn(err)}
  }

  const removeGroupClick = (key) => {
    removeGroup(key)
  }

  return (
    <Styled.Projection>
      <ProjectionsTour show={showTour} />

      <Styled.Groups>
        <Styled.GroupTabs onSelect={setActiveGroup} activeKey={activeGroup}>
          {groupsKeys.length > 0 && groupsKeys.map(groupId =>
            <Tabs.Tab key={groupId} tabKey={groupId} onRemove={removeGroupClick} style={{"--color": groups[groupId].color}}>
              <span>{t('future')}</span>
            </Tabs.Tab>
          )}
          <Styled.CreateTabBtn
            as={"button"}
            className='CreateTabBtn'
            onClick={createGroup}
            title={t("newGroupButton.title", {MAX_GROUPS})}
            data-disable={groupsKeys.length >= MAX_GROUPS ? '' : null}>
            ✚
          </Styled.CreateTabBtn>
        </Styled.GroupTabs>

        <Tabs.Content>
          <Group id={activeGroup} />
        </Tabs.Content>
      </Styled.Groups>

      <ForecastChart projections={projections} groups={groups} />
    </Styled.Projection>
  )
}

export default Projection