// https://github.com/system-ui/theme-specification

export const colors = {
  gray: '#646466',
  grays: ['#CCC', '#ECECEC', '#F0F0F0'],

  blacks: ['#111', '#444', '#666'],

  blue: '#2E93fA',
  blues: ['#4FA2BC', '#CEE5EC'],
  lightblue: '#EBF0F7',

  purple: '#806EF0',
  purples: ['#4A148C', '#AB47BC'],
  lightpurple: '#E8D5EF',

  green: '#2E8B57',
  green500: '#55efa5',
  lightgreen: '#DCEAE3',

  yellow: '#FFD700',

  red: '#E91E63',
  pink: '#F72585',

 // pallete: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800']
  pallete: ['#F72585', '#3A0CA3', '#806EF0', '#4895ef', '#a8dadc']
}

const fonts = {}
fonts.body = 'Roboto, "Helvetica Neue", sans-serif'

const fontSizes = [0, '.9em', '1.2em', '1.4em', '2em', '4em']
fontSizes.small = fontSizes[1]
fontSizes.medium = fontSizes[3]
fontSizes.large = fontSizes[4]
fontSizes.huge = fontSizes[5]

const space = [0, 4, 10, 16, 32, 42, 64]
space.small = space[2]
space.sm = space[3]
space.medium = space[4]
space.large = space[6]
space.headerHeight = '50px'

const sizes = [0, 100, 300, 500, 700]
sizes.large = sizes[4]
sizes.headerHeight = '50px'

const zIndices = [0, 10, 100, 1000, 99999]
zIndices.above = zIndices[1]
zIndices.high = zIndices[2]
zIndices.higher = zIndices[3]
zIndices.max = zIndices[4]

const radii = [0, 3, 5, 8, 10]

const transitions = {
  normal: '.15s ease-out'
}

const shadows = [0, '0 2px 5px rgba(0, 0, 0, .1)']

shadows.inset1 = '0 2px 5px rgba(0, 0, 0, .1) inset'

const screens = {
  m: 640,
  l: 1024,
  xl: 1280,
}

export default {
  colors,
  fonts,
  fontSizes,
  space,
  sizes,
  zIndices,
  radii,
  shadows,
  transitions,
  screens
}
