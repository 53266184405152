import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

const Tldr = () => {
  const {t} = useTranslation()

  return <Trans t={t} i18nKey="tldr">
    <p>
      Welcome to <em>Future-Fortune</em> - A financial calculator which was build to provide comparable projections regarding
      your financial savings using various parameters, which I've concluded are the minimum necessary
      for a rough estimation to the pile of money will you have after a certain amount of years (by investing it all).
    </p>
    <p>
      This savings calculator aim, at least for me, to roughly plan my financial future, knowing when will
      I be free and have enough money to retire, living a life of financial independence and be a happier person by feeling safe I will have
      enough money saved to last me through the years, as I want to retire as early as possible.
    </p>
    <p>
      Please write me for anything: <a href="mailto:contact@future-fortune.com">Yair Even Or</a>
    </p>
  </Trans>
}

export default Tldr