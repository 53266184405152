import React from 'react'
import { useTranslation } from 'react-i18next'
import { Projection } from 'components/organisms'
import { ProjectionsProvider } from 'store/context.js'
// import { Comments, ShowIf } from 'components/atoms'
import { MainHeader } from 'components/molecules/index'
import FAQ from './FAQ'
import * as Styled from'./Home.styles'

const Home = () => {
  const { t } = useTranslation()

  return <>
    <ProjectionsProvider>
      <MainHeader/>
      <Projection/>
      <FAQ/>

      {/* <Styled.Comments>
        <header>
          <h2>{t(`comments.title`)}</h2>
          <p>{t(`comments.subtitle`)}</p>
          <Styled.ChatPointer>👇</Styled.ChatPointer>
        </header>
        <Comments/>
      </Styled.Comments> */}
    </ProjectionsProvider>
  </>
}

export default Home