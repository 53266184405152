import React from 'react'
import styled from '@xstyled/styled-components'
import { cx, getCSSVars } from 'utils'

const StyledIconBase = styled.i`
  display: inline-block;
  width: var(--size, 24px);
  height: var(--size, 24px);
  transition: .1s;
  mask-image: var(--src);
  background: gray;
`

const StyledIcon = styled(StyledIconBase)`
  ${getCSSVars('size', 'src')}
`

// using icons from:
// https://svgbox.net/
const Icon = ({ src = '', size, className }) =>
// src={`url(https://${src})`}
  <StyledIcon className={cx('icon', className)} size={size} style={{ "WebkitMaskImage":`url(https://${src})` }} />

export default React.memo(Icon)
