import { useProjectionsContext } from 'store/context.js'

const useTour = () => {
  const { setShowTour } = useProjectionsContext()

  const showTour = () => {
    setShowTour( Math.random() + 1 )
  }

  return { showTour }
}

export default useTour