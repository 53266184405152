import React, { useCallback, useEffect, useRef } from 'react'
import styled, { th } from '@xstyled/styled-components'
import { ShowIf } from 'components/atoms'
import Tldr from './Tldr'
import Calculations from './Calculations'
import media from 'styles/mq'

/*
const diagonalBg = (c1, c2, size = '22px', deg = 45) =>
  `background-image: linear-gradient(${deg}deg,
      ${c1} 25%, ${c2} 25%,
      ${c2} 50%, ${c1} 50%,
      ${c1} 75%, ${c2} 75%,
      ${c2} 100%);
    background-size: ${size} ${size};
  `
*/

const StyledFaq = styled.section`
  --angle: 22deg;

  display: flex;
  gap: 1vw;
  padding: 1vw;

  html:lang(he) &{
    direction: rtl;
  }

  p{
    margin: 0 0 2em;
    font-size: 3;

    &:last-of-type{ margin: 0; }
  }

  > aside{
    --border-width: 8px;

    flex: 1;
    border-radius: 3;
  //  border: 3px solid;
    //border-color: purple;
    padding: medium;

    border: var(--border-width) solid transparent;
    border-radius: 2vmin;
    background-image: linear-gradient(${th.color('lightblue')}, ${th.color('lightblue')}),
                      linear-gradient(var(--border-angle, var(--angle)), transparent 15%, ${th.color('purple')});
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;

    &:nth-child(2){
      --border-angle: calc(var(--angle) * -1);
      // margin-left: calc(var(--border-width) * -1);
    }

    ${media.below("mobile")`
      background: none;
      padding: 4 0px 0px;
    `}
  }

  ${media.below("mobile")`
    flex-flow: column;
  `}
`

const StyledTldr = styled.aside`
  font-size: 2;
`

const FAQ = () => {
  const wrapperRef = useRef()

  const onBodyScroll = useCallback(() => {
    const deg = document.documentElement.scrollTop/5 % 360
    wrapperRef.current.style.setProperty('--angle', deg + 'deg')
  }, [])

  useEffect(() => {
    let callback = function (entries, observer) {
      entries.forEach(entry => {
        window[(entry.isIntersecting ? 'add': 'remove') + 'EventListener']('scroll', onBodyScroll, {passive: true})
      })
    }

    let observer = new IntersectionObserver(callback)
    observer.observe(wrapperRef.current)
    // use intersection observer
    // when element in view, get the delta from the viewport's top
    // use that as the angle CSS variable which will be set
  }, [])

  return (
    <StyledFaq ref={wrapperRef} className="Faq">
      <StyledTldr>
        <Tldr/>
      </StyledTldr>
      <aside>
        <Calculations/>
      </aside>
    </StyledFaq>
  )
}

export default FAQ